import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const About = ({data: {site, allFile }, location}) => {
    const siteTitle = site.siteMetadata?.title || `Title`
    const about = allFile.nodes[1].childMarkdownRemark
    const founder = allFile.nodes[0].childMarkdownRemark

    // To-do:
    // const aboutBody = allFile.nodes.filter((a) => a.name === "about-list")
    // const founderBody = allFile.nodes.filter((a)=> a.name === "meet-founder")
    
    
    return(
        <Layout location={location} title={siteTitle}>
            <div className="about">
              <StaticImage
                layout="fixed"
                formats={["auto", "webp", "avif"]}
                src="../images/vision-board-squiggle.png"
                height={200}
                width={200}
                quality={95}
                alt="vision board"
              />
              <section
                dangerouslySetInnerHTML={{ __html: about.html }}
                itemProp="aboutBody"
              />
              <StaticImage
                className="about-founder-image"
                layout="fixed"
                formats={["auto", "webp", "avif"]}
                src="../images/about-squiggle.png"
                height={200}
                width={200}
                quality={95}
                alt="founder profile"
              />
              <section
                dangerouslySetInnerHTML={{ __html: founder.html }}
                itemProp="founderBody"
              />
            </div>
        </Layout>
    )
}

export default About

export const Head = () => <Seo title="Zai Duck Wellness About" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: {name: {in: ["about-list", "meet-founder"]}}) {
      nodes {
        name
        childMarkdownRemark {
          html
        }
      }
    }
}`
